@import './variables.sass'
@import './mixins.sass'
@import './layout.sass'
@import './modal.sass'
@import './button.sass'
@import './table.sass'
@import './forms.sass'
@import './badge.sass'
@import './employee.sass'
@import './device.sass'
@import './job.sass'
@import './transaction.sass'
@import './inputs'
@import './crew.sass'
@import './stepper.sass'
@import './settings.sass'
@import './pay-period.sass'
@import './report.sass'
@import './subscription.sass'
@import './help.sass'
@import './tv.sass'
@import './survey.sass'
@import './typography.sass'
@import './messaging.sass'
@import './dropdown-menu.sass'

*
  box-sizing: border-box
*::placeholder
  font-family: $font-family

h1, h2, h3, h4, h5
  padding: 0
  margin: 0

.theme-dark
  color: $grey-light
  h1, h2, h3, h4, h5
    color: $grey-light

html
  padding: 0
  background: $bg
  height: 100%
  width: 100%
  font-size: $base-font
  color: $text

body
  overflow-x: hidden
  font-family: $font-family
  min-height: 100%

a
  color: $primary

.map-marker
  position: relative
  span
    position: absolute
    top: -20px
    left: 6px
    text-align: center
    font-weight: 400
    font-size: 1.2rem

.theme-dark
  .content
    background: $app-bg-dark
.map-container
  min-width: 400px
  flex: 1
  height: 600px
  .instructions
    font-size: 0.9rem
    color: $on-bg
    padding: 10px 0
    span
      margin-right: 40px
.map
  min-width: 400px
  max-height: 600px
  border-radius: 5px
  overflow: hidden
  flex: 1
.transaction-map
  display: flex
  flex-direction: row
  flex: 1
  width: 100%
  ul
    list-style: none
    padding: 0 $base-pad
    margin: 0
    li
      padding: $base-pad/3 0
    .icon
      margin-left: $base-pad / 3
    .identifier
      color: $grey-medium
      padding-right: $base-pad / 2

.mapboxgl-popup-content
  padding: 5px 10px
  color: $on-bg

.theme-dark
  .map-container .instructions
    color: $on-primary
  .mapboxgl-popup-tip
    border-top-color: $black
  .mapboxgl-popup-content
    background: $black
    color: $on-primary

.center-content
  display: flex
  flex: 1
  justify-content: center

.app, #root
  height: 100vh
  width: 100vw

.auth-container
  display: flex
  height: 100%
  width: 100%
  justify-content: center
  align-items: center
  background: $content-bg
  h1
    +font-scale(3)
  .onboard-survey
    display: flex
    flex-direction: column
  .login
    width: 400px
    .logo img
      max-width: 350px
      margin-top: 15px
      margin-bottom: 30px
  .register
    margin: 0 50px
    min-width: 400px
    max-width: 800px
    display: flex
    flex-direction: column
    .logo img
      max-width: 350px
      margin-top: 15px
      margin-bottom: 30px
    p
      max-width: 370px
    h2
      margin: 0 0 $base-pad 0
      font-weight: 200
      color: rgba($text, 0.6)
      +font-scale(0.6)
    .col
      flex: 1
      padding-right: $base-pad
      &.divider
        padding: 0
        flex: 0
        border-left: 2px dashed rgba(white, 0.15)
        margin: $base-pad*2 0
      &:last-child
        flex: 2
        padding-left: $base-pad
        padding-right: 0
  > .onboard-survey
    p
      max-width: 400px
  > .login, > .register, > .onboard-survey
    background: $card-bg
    padding: 20px
    border-radius: 5px
    @include shadow
    form
      padding: $base-pad 0
      .button
        margin-top: $base-pad
        width: 100%
      .google-auth
        margin-top: 0
        margin-bottom: 20px
    .link
      text-align: center
  > .login, > .register
    form
      .form-input:not(:last-child)
        margin-bottom: $base-pad
.menu
  background: $white
  overflow-y: scroll
  max-height: 250px
  .menu-item
    padding: $base-pad/3 $base-pad/2
    transition: background 0.2s, color 0.2s, box-shadow 0.2s
    align-items: center
    display: flex
    justify-content: space-between
    cursor: pointer
    border-radius: 3px
    small
      color: lighten($on-bg, 50%)
      padding-left: 10px
    &.active, &:hover
      background: $primary
      color: $on-primary
      box-shadow: 0 1px 0 darken($primary, 10%)
      small
        color: darken($on-primary, 10%)
.theme-dark .menu
  background: transparent
  .menu-item
    color: $grey-light
    &.active
      color: $white
.note-popover-trigger
  position: relative
  .button
    display: inline-block
.popover
  background: $card-bg
  min-height: 50px
  margin-top: 2px
  border-radius: 4px
  z-index: 1000
  +elevation-3
  &.popover-menu
    padding: 5px
    min-width: 250px
    // .menu
    //   overflow-y: hidden
  &.timezone-popover
    padding: $base-pad
    width: 400px
    .row
      justify-content: space-between
      margin-top: $base-pad
  &.confirmation-popover
    padding: $base-pad
    max-width: 500px
    .row
      justify-content: space-between
  &.note-popover
    min-width: 300px
    max-width: 400px
    padding: $base-pad
    text-align: left
    position: fixed
    h3
      +font-scale(1.0)
      margin-bottom: 5px
  &.time-input
    display: flex
    flex-direction: column
    .col
      padding: 0
      margin: 0
    .title
      text-align: center
      padding-right: 7px
    .menu
      flex: 1
    .row:first-child
      border-bottom: 1px solid lighten($on-bg, 80%)
    .row
      padding: $base-pad /3
      justify-content: space-between
.callout-container
  display: flex
  flex-direction: column
  gap: 10px
  margin-bottom: 25px
  &:empty
    margin-bottom: 0
.rounded-callout
  display: flex
  align-items: center
  color: $white
  border-radius: 5px
  margin-bottom: $base-pad
  padding: $base-pad/2 $base-pad
  margin: 0 $base-pad
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)
  font-weight: 500
  font-size: 14px
  line-height: 15px
  &.error
    background: $red
    color: $white
  &.info
    background: $blue
    color: $white
  pre
    font-family: inherit
    white-space: pre-wrap
    padding: 0
    margin: 0
.flat-callout
  display: flex
  color: $white
  margin-bottom: $base-pad
  padding: $base-pad/2 $base-pad
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)
  ul
    padding-left: 20px
    font-size: 13px
    margin: 8px 0 0 0
  a
    color: white
    &:hover
      color: black
  &.error
    background: $red
  &.info
    background: $blue
  pre
    font-family: inherit
    white-space: pre-wrap
    padding: 0
    margin: 0
.minimal-callout
  color: $grey-dark
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  svg
    color: $green
    margin-right: 20px
.theme-dark .minimal-callout
  color: $grey-light
.callout
  display: flex
  flex-direction: column
  padding: $base-pad
  margin: $base-pad/2 0
  border-radius: 4px
  +elevation-3
  ul
    color: $on-primary
    padding-left: $base-pad
    margin-bottom: 0
  &.error
    background: $red
    color: $on-primary
  .title
    color: $on-primary
    font-weight: bold
    text-shadow: 0 1px 1px rgba(#000, 0.3)
  &.info
    background: $primary
  &.success
    background: $clock-in-color
    .title
      color: rgba($white, 0.9)
  &.warning
    background: $blue
  .message
    color: darken($on-primary, 4%)
    text-shadow: 0 1px 1px rgba(#000, 0.2)
    &:not(:first-child)
      margin-top: 10px
.trial-extension
  > span
    font-size: 14px
    margin-bottom: 4px
    &:not(:first-child)
      color: $grey-medium
  .button
    margin: 10px 0
.link
  color: $link-color
  cursor: pointer
  &:hover
    color: darken($link-color, 20%)

::-webkit-scrollbar
  width: 8px
::-webkit-scrollbar-track
  background: transparent
  color: blue
::-webkit-scrollbar-thumb
  border-radius: 4px
  background: rgba(0,0,0,0.5)

.legacy-device
  display: flex
  align-items: center
  color: $red
  svg
    margin-right: 5px

.activity-indicator
  width: 60px
  height: 16px
  > div
    margin: 0 5px
    width: 8px
    height: 8px
    background: white
    border-radius: 50%
    display: inline-block
    animation: activity-bounce 1.0s infinite ease-in-out both
  .indicator-1
    animation-delay: -0.2s
  .indicator-2
    animation-delay: -0.4s
@keyframes activity-bounce
  0%, 80%, 100%
    transform: scale(0)
  40%
    transform: scale(1.0)
.container
  display: flex
  min-height: 100%

.logo
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  margin-bottom: $base-pad / 2
  h1
    font-family: 'Montserrat', 'Lato', sans-serif
    font-weight: 800
    font-size: 1.0rem
  h1:first-of-type
    color: $black
    font-weight: 800
    margin-right: 7px
  h1:last-of-type
    color: $blue
  img
    flex: 1
    margin: 0
  &.medium
    h1
      font-size: 1.8rem
      &:first-of-type
        margin-right: 10px
.theme-dark .logo
  h1:first-of-type
    color: $on-primary
.drawer
  display: flex
  flex-direction: column
  width: $drawer-width
  background: $drawer-bg
  z-index: 900
  box-shadow: 4px 0px 5px -2px rgba(0,0,0,0.2)
  height: 100%
  max-height: 100%
  position: fixed
  top: 0
  left: 0
  +font-scale(-0.5)
  .badge.plus-feature
    font-size: 0.7rem
  > .logo
    margin: $base-pad / 2 0 0 $base-pad*1.5
    padding-right: $base-pad
    margin-top: 20px
  .mini-menu
    margin-top: auto
    .item
      padding: $base-pad/4 $base-pad*1.5
      text-transform: uppercase
      font-weight: 600
      +font-scale(-1.5)
    .metadata
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: $base-pad/2 $base-pad*1.5
      a
        color: lighten($on-bg, 50%)
        margin-bottom: $base-pad/3
        &:hover
          color: $primary
      .cc
        color: lighten($on-bg, 50%)
        +font-scale(-1.0)
      .version
        margin-top: 8px
        color: lighten($on-bg, 60%)
        +font-scale(-1.5)
        > a
          margin-left: 10px
  .profile
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 0 $base-pad/2 0 $base-pad*1.5
    margin: $base-pad 0
    .avatar
      margin-right: $base-pad/2
      margin-bottom: 10px
      flex-shrink: 0
    .label
      display: flex
      max-width: 100%
      flex-direction: column
      overflow: hidden
      .org
        color: lighten($text, 30%)
        font-size: 1rem
        flex: 1
        font-weight: 600
      .user
        flex: 1
        color: lighten($text, 60%)
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

  .category
    margin-top: $base-pad/2
    color: #828282
    > .title
      padding: $base-pad/2 $base-pad*1.5
      text-transform: uppercase
      font-weight: 600
      +font-scale(-1.5)
  .item
    color: $text
    display: flex
    align-items: center
    text-decoration: none
    padding: $base-pad/1.8 $base-pad*1.5
    transition: background 0.2s, color 0.2s
    cursor: pointer
    font-weight: 600
    border-right: 4px solid transparent
    transition: background 0.3s, color 0.3s, border-color 0.3s
    +font-scale(-0.5)
    .badge, .popover-container
      margin-left: auto
    > svg
      color: rgba(darken($primary, 40%), 0.7)
      margin-right: $base-pad/2
    &:hover, &.active
      border-right: 4px solid $primary
      color: $primary
      > svg
        color: $primary
.theme-dark .drawer
  background: $drawer-bg-dark
  .category
    color: $drawer-category-dark
  .item
    color: $drawer-item-dark
    > svg
      color: rgba(lighten($primary, 45%), 0.8)
  .profile .label
    .org
      color: $grey-light
    .user
      color: $grey-medium

.avatar
  width: 48px
  height: 48px
  display: flex
  text-transform: uppercase
  font-size: 1.2rem
  justify-content: center
  align-items: center
  background: $primary
  border-radius: 50%
  color: $on-primary
  user-select: none
.content
  flex: 1
  min-width: 0
  background: $content-bg
  &.has-drawer
    margin-left: $drawer-width
.page
  // overflow-x: scroll
  .page-content
    padding: $base-pad
  .split-header
    display: flex
    flex: 1
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: $base-pad/2
    .form-input
      flex: 0
      min-width: auto
.toolbars
  background: $toolbar-bg
  position: sticky
  top: 0px
  left: 0px
  z-index: 997
  +elevation-3
  .divider-horizontal
    flex: 1
    height: 1px
    margin: 0 $base-pad
    border-bottom: 1px solid rgba(0,0,0,0.2)
  .divider-vertical
    align-self: stretch
    margin-right: $base-pad/2
    border-right: 1px solid rgba(0,0,0,0.2)
.theme-dark .toolbars
  background: $drawer-bg-dark
.toolbar
  display: flex
  align-items: center
  justify-content: space-between
  flex: 1
  padding: $base-pad/1.5 $base-pad
  &.small
  .item
    display: flex
    flex-direction: column
    align-self: stretch
    justify-content: space-between
    &:not(:only-of-type)
      margin-right: $base-pad/2
    > .label
      text-transform: uppercase
      font-weight: 600
      margin-bottom: $base-pad/4
      color: lighten($on-bg, 50%)
      +font-scale(-1.7)
  .toolbar-group
    display: flex
    align-items: center
    .button
      max-height: 43px
    .switch:not(.input)
      margin-left: $base-pad/2
      margin-top: 5px
    > .button:not(:first-child)
      margin-left: $base-pad/2
    .button-group
      .button
        padding: $base-pad/4 $base-pad/2
        margin-left: 0
        +font-scale(-1.5)
    &.right
      justify-content: flex-end
.markdown
  h1, h2, h3, h4
    font-family: 'Montserrat'
    text-transform: uppercase
  img
    border-radius: 5px
    margin-left: 40px
    +elevation-3
  h1
    font-size: 1rem
    margin-top: 20px
    display: flex
    align-items: center
    .badge
      display: flex
      height: 20px
      margin-left: 10px
  h4
    font-family: 'Lato'
    font-weight: 400
    font-size: 0.8rem
    font-style: italic
  ul
    margin: 5px 0
    li
      margin-bottom: 4px
  blockquote
    border-left: $on-bg 5px solid
    padding-left: 20px
    font-style: italic
    color: lighten($on-bg, 20%)
.theme-dark .markdown
  blockquote
    border-left-color: darken($on-primary, 20%)
    p
      color: darken($on-primary, 40%)
.changelog
  .content
    max-width: 70ch
    margin: 0 auto
    > h1
      margin: 20px 0
      font-size: 1.2rem
      font-family: 'Montserrat'
      text-transform: uppercase

.tooltip
  padding: 20px
  z-index: 999
  background: white
  +elevation-3
  h1
    font-size: 1.1rem
    text-transform: uppercase
    margin-bottom: $base-pad / 2
  img
    border: 1px solid darken($app-bg, 40%)
    border-radius: 5px
  p
    margin: 0
    padding: 0 0 $base-pad 0
  &.dark
    background: $app-bg-dark
    color: $on-primary
    img
      border-color: darken($on-primary, 0%)

.customer-messages
  display: flex
  flex: 1
  .banner
    flex: 1
    display: flex
    flex-direction: row
    background-color: $blue
    padding: 10px 20px
    color: $white
    align-items: center
    > span
      font-size: 1.2rem
    .action
      margin-left: 20px
      color: $on-bg
      background: white
      flex-shrink: 0
    .button:last-of-type
      color: $on-primary
      margin-left: auto

.registration-code
  border: 1px solid rgba(#000, 0.3)
  border-radius: 3px
  background: rgba(#000, 0.1)
  align-self: flex-start
  > span
    padding: $base-pad /4 $base-pad/3
    text-transform: uppercase
    &:not(:first-child)
      border-left: 1px solid rgba(#000, 0.3)

.tabs
  margin-top: $base-pad/2
  background: rgba(#000, 0.1)
  flex: 1
  overflow: hidden
  border-radius: 4px
  .tab-bar
    background: rgba(#000, 0.3)
    border-bottom: 2px solid rgba(#000, 0.2)
    display: flex
    flex-direction: row
    .tab-button
      padding: $base-pad/2 $base-pad
      color: $on-primary
      cursor: pointer
      transition: background 0.2s
      &.active, &:hover
        background: $primary
  .tab-content
    position: relative
    display: inline-flex
    align-items: flex-start
    flex-direction: row
    overflow-x: hidden
    min-width: 100%
    .tab
      padding: 0 $base-pad/2
      position: absolute
      transition: left 0.3s
      top: 0
      left: 100%
      width: 100%
      &.active
        left: 0
.toaster
  position: fixed
  z-index: 9999
  bottom: 0
  left: 0
  padding: $base-pad
  display: flex
  flex-direction: column
  max-width: 450px
  pointer-events: none
  > div
    position: relative
    overflow: hidden
    background: lighten($on-bg, 9%)
    border-radius: 5px
    padding: $base-pad
    color: $on-primary
    margin-top: $base-pad
    +elevation-3
    strong
      color: white
    p
      color: darken($on-primary, 20%)
      margin-bottom: 0
      padding-bottom: 0
    .icon
      margin-right: $base-pad/2
.theme-dark
  .toaster > div
    background: black
.empty-map-state
  width: 100%
  height: 150px
  display: flex
  justify-content: center
  align-items: center
  position: relative
  .img
    position: absolute
    top: 0
    left: 0
    filter: blur(3px)
    width: 100%
    height: 150px
    background: url('/img/default-map.png')
    background-position: center
  span
    background: $white
    padding: $base-pad/2 $base-pad
    border-radius: $base-pad
    z-index: 10
    +elevation-2
.capitalize
  text-transform: capitalize
.empty-state
  p
    max-width: 500px
    font-weight: 400
  .button
    margin-top: $base-pad
.backdrop
  background: rgba($black, 0.4)
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 999
.tab-menu
  display: flex
  flex-direction: row
  margin-bottom: $base-pad
  align-items: stretch
  .view-as
    display: flex
    flex-direction: row
    align-items: center
    > span
      margin-right: $base-pad/4
      text-transform: uppercase
      font-weight: 600
      color: lighten($on-bg, 50%)
      +font-scale(-1)
    .button
      padding: 6px
      &.active
        background: $primary
        color: $on-primary
        border-radius: 3px
      .icon
        margin-right: 0
  a
    padding: $base-pad
    text-align: center
    color: $on-bg
    font-weight: 600
    transition: background 0.2s
    border-bottom: 1px solid #ccc
    text-decoration: none
    &:hover, &.active
      border-bottom: 3px solid $primary
      color: $primary
  >div
    border-bottom: 1px solid #ccc
    &.metadata
      display: flex
      justify-content: center
      flex-direction: row
      align-items: center
      > div
        &:not(:last-of-type)
          margin-right: $base-pad/2
      .timezone
        display: flex
        flex-direction: column
      > .value
        font-style: italic
        font-weight: 400
        color: $on-bg
        +font-scale(-1)
      > .label
        text-transform: uppercase
        font-weight: 600
        color: lighten($on-bg, 50%)
        +font-scale(-1)
    &.spacer
      flex: 1
.theme-dark .tab-menu
  a
    color: $grey-light
    &:not(.active)
      border-bottom: 1px solid $black
    &.active
    &:hover, &.active
      color: $primary
  >div
    border-bottom: 1px solid $black
.theme-dark
  p
    color: $grey-light
  .card
    color: $grey-light
    background: $drawer-bg-dark
    box-shadow: 0px 4px 8px #212121
.theme-dark .popover
  background: $app-bg-dark

.popover-container
.popover-content
  z-index: 999
  background: #EAEAEA
  padding: 16px
  font-family: Lato
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 132.3%
  color: #383838
  +elevation-1
  &[data-open^='false']
    display: none
  &[data-open^='true']
    display: flex
  &[data-popper-placement^='top'] > .arrow
    bottom: -4px
  &[data-popper-placement^='bottom'] > .arrow
    top: -4px
  &[data-popper-placement^='left'] > .arrow
    right: -4px
  &[data-popper-placement^='right'] > .arrow
    left: -4px
  .arrow, .arrow::before
    position: absolute
    width: 8px
    height: 8px
    background: inherit
  .arrow
    visibility: hidden
    &::before
      visibility: visible
      content: ''
      transform: rotate(45deg)

.theme-dark .popover-content
  background: $card-bg-dark
  color: $grey-light

.center
  display: flex
  position: relative
  flex: 1

@keyframes spinner
  to
    transform: rotate(360deg)

$spin-width: 60px
$spin-height: 60px
.spinner-container
  display: flex
  height: $spin-height
  justify-content: center
  align-items: center
.spinner
  box-sizing: border-box
  width: $spin-width
  height: $spin-height
  border-radius: 50%
  border-top: 2px solid $primary
  border-right: 2px solid transparent
  animation: spinner .6s linear infinite
  &.floating
    position: absolute
    top: 50%
    left: 50%
.spinner.small
  width: $spin-width / 3
  height: $spin-height / 3
.spinner.tiny
  width: 16px
  height: 16px

.alert-container
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(black, 0.3)
  z-index: 999
  display: flex
  justify-content: center
  align-items: center
  +elevation-3()
  .alert-dialog
    padding: 20px
    border-radius: 5px
    background: $app-bg
    pre
      margin: 0
      font-family: Lato
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 16px
      background: rgba(black, 0.2)
      border-radius: 5px
      padding: 10px
      max-width: 400px
      white-space: pre-wrap
      word-wrap: break-word
    h1
      text-align: center
    .actions
      display: flex
      flex-direction: row
      justify-content: space-around
      margin-top: 20px

.theme-dark .alert-dialog
  background: $app-bg-dark

@media print
  body
    font-size: 12px
  table, tr
    page-break-after: auto
  .table
    box-shadow: none
    padding-bottom: 20px
  .toolbars
    box-shadow: none
  .tab-menu
    margin-bottom: 5px
    a
      padding: 4px 20px
  .page .page-content
    padding: 0
  .drawer
    display: none
  .content.has-drawer
    margin-left: 0
  .toolbars > .toolbar
    display: none
  .pay-period .input-bar .chevron-button
    display: none
  .page.employees
    .toolbars
      h1
        font-size: 1rem
      .toolbar:first-of-type
        .toolbar-group.right .button
          display: none
      .toolbar
        display: flex
        border-bottom: none
        .toolbar-group.right .item:last-of-type
          display: none
  .employees-report .toolbars > .toolbar.small
    display: flex
