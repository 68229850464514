.device-edit
  .page-content
    max-width: 960px
    margin: 0 auto
.device-modal
  .modal
    transition: width 0.2s, height 0.2s
    max-width: 80%
  .col > form > .button
    width: 100%
.device-card
  &:hover
    background: darken($bg, 1%)
    @include shadow-strong
  .message
    margin: $base-pad/3 0
  .name
    color: $primary
    @include font-scale(1)
  .pay-rate
    color: rgba($text, 0.6)
    @include font-scale(-0.8)

.email-form, .phone-form
  .button
    margin-top: $base-pad
    margin-left: auto
.dash-panel
  h2
    color: rgba($text, 0.5)
    @include font-scale(0.6)
    &
      font-weight: 200
      margin-bottom: $base-pad/2

.device-select-row
  max-width: 450px
  margin-bottom: $base-pad

.table-reg-column
  display: flex
  flex-direction: column
  align-items: flex-start

.flex-grid
  display: flex
  .col
    margin: $base-pad/2 0
    flex: 1
    &:not(:last-child)
      margin-right: $base-pad
