@import './variables.sass'

=shadow
  box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.15)
=shadow-light
  box-shadow: 0 5px 8px rgba(0,0,0,0.05), 0 4px 3px rgba(0,0,0,0.6)
=shadow-strong
  box-shadow: 0 10px 15px rgba(0,0,0,0.15), 0 8px 6px rgba(0,0,0,0.11)
=shadow-inset
  box-shadow: inset 0 1px 4px rgba(0,0,0,0.3)
=shadow-inset-up
  box-shadow: inset 0 -1px 3px rgba(0,0,0,0.2)

=elevation-1
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
=elevation-2
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
=elevation-3
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)
=elevation-4
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)
=elevation-5
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)
=elevation-6
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)

=light-text-shadow
  text-shadow: 0 2px 1px rgba(#000, 0.2)

=font-scale($scale)
  font-size: (1rem + ($font-scale * $scale))

=button-color($color, $hover-color)
  background: $color
  color: $white
  &.outline
    color: $color
    border-color: $color
    &:hover
      color: $on-primary
      border-color: $hover-color
  &:hover
    background: $hover-color
  &.secondary
    background: transparent
    color: $on-bg
    &:hover
      color: $on-primary
      background: $hover-color
  &.active
    color: $on-primary
    background: $color

=card
  background: $card-bg
  display: flex
  flex-direction: column
  border-radius: 1px
  transition: background 0.2s, box-shadow 0.2s
  // border: 1px solid #ddd
  align-items: flex-start
  overflow: hidden
  position: relative
  box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.15)
=flex-row
  display: flex
  flex-direction: row
