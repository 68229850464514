.survey-container
  z-index: 999
  position: fixed
  bottom: 0
  right: 0

$survey-pad: 20px

.survey
  margin: $base-pad
  padding: $base-pad
  width: 588px
  +card()
  padding: $survey-pad
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15)
  align-items: stretch
  .survey-header
    flex-direction: row
    display: flex
    align-items: center
    justify-content: space-between
  h1
    font-size: 20px
  .close-button
    transition: opacity 0.3s, transform 0.3s, background 0.3s
    transform: scale(1)
    transform-origin: center
    background: transparent
    border-radius: 50%
    width: 30px
    height: 30px
    align-items: center
    justify-content: center
    display: flex
    &:hover
      background: $green
      transform: scale(1.3)
  .description
    font-size: 13px
    line-height: 15px
    ul
      font-style: normal
      font-weight: normal
      font-size: 13px
      line-height: 20px
      letter-spacing: -0.02em
      padding-left: 20px
  .feedback
    margin-bottom: $base-pad
  .question
    p
      font-family: Lato
      font-style: normal
      font-weight: 500
      font-size: 13px
      line-height: 150%
      letter-spacing: 0.01em
    label
      font-weight: 600
      font-size: 13px
      line-height: 15px
      letter-spacing: -0.02em
    .slider
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      padding-top: 10px
      padding-bottom: 20px
      div:first-child
        padding-left: 20px
        flex: 5
      div:last-child
        font-size: 18px
        padding-bottom: 30px
        padding-left: 20px
        font-weight: 800
        flex: 1
        &.error
          font-size: 13px
          line-height: 12px
          text-transform: uppercase

  .complete-container
    margin-top: 20px
    padding: 16px
    max-width: 470px
    text-align: center
    flex-direction: column
    justify-content: center
    display: flex
    .button
      margin-top: 30px
      align-self: center
    p
      margin-top: 30px
      font-family: Lato
      font-style: normal
      font-weight: 500
      font-size: 13px
      line-height: 150%
      text-align: center
      letter-spacing: 0.01em

  .answer
    display: flex
    flex: 1
    cursor: pointer
    padding: 13px 16px
    transition: background 0.2s
    font-family: Lato
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 19px
    div:last-child
      margin-left: auto
    span
      margin-right: $base-pad/2
    &:nth-child(odd)
      background: $table-row-alt-bg
      &.disabled
        background: lighten($table-row-alt-bg, 2%)
    &:hover
      background: darken($table-row-alt-bg, 4%)
    &.disabled
      color: lighten($text, 40%)
      cursor: initial
      &:hover
        background: inherit
    .trigger
      color: $grey-medium
      width: 24px
      height: 24px
      padding: 4px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 50%
      overflow: hidden
      &:hover
        border-color: $primary
        color: $grey-medium
        svg
          color: $primary
        .icon
          color: $primary
  textarea
    min-height: 90px
  .actions
    align-self: stretch
    flex: 1
    justify-content: center
    display: flex
    flex-direction: row
    .button:first-of-type
      margin-right: $base-pad
  .popover-content
    max-width: 400px
    margin: 0 20px
    border-radius: 3px

.theme-dark .survey
  background: lighten($card-bg-dark, 2%)
  label
    color: $white
  .answer
    &:hover
      background: darken($table-row-alt-bg-dark, 4%)
      &.disabled
        background: inherit
    &:nth-child(odd)
      background: $table-row-alt-bg-dark
      &.disabled
        background: lighten($table-row-alt-bg-dark, 1%)

.survey
  &.complete
    text-align: center
  textarea
    max-width: 100%
  .survey-footer
    display: flex
    flex-direction: row
    justify-content: center
    padding-top: 20px
  .md
    @extend .p
    p
      margin-bottom: 5px
    ul
      margin: 0
.survey-tile-picker
  display: flex
  flex-direction: row
  justify-content: center
  padding: 20px 0
  .tile
    border-radius: 3px
    border: 1px solid black
    margin-right: 20px
    padding: 10px 40px
    cursor: pointer
    font-weight: 800
    &.active
      background: $primary
      color: $white
      border: none

.theme-dark .survey-tile-picker
  .tile
    border-color: $grey-medium
