.subscription-settings
  max-width: 960px
  a
    font-weight: 800
    text-decoration: none
    &:hover
      color: darken($blue, 20%)
  .cancel
    border: 1px solid $grey-light
    padding: 20px
    grid-column: 1 / span 2
$h: 40px
$w: 200px
.subscription-header
  display: flex
  flex-direction: row
  padding-bottom: 20px
  align-items: center
  padding-left: 150px
  cursor: pointer
  .promo
    display: flex
    flex-direction: row
    align-items: center
    margin-left: 20px
    color: $grey-light
    transition: color 0.3s
    &.active
      color: $green
    svg
      margin-right: 5px
  .period-switch
    display: flex
    justify-content: space-between
    align-items: center
    width: 200px
    flex-grow: 0
    background: $primary
    border-radius: $h / 2
    height: $h
    padding: 3px
    position: relative
    span
      display: block
      flex: 1
      text-align: center
      color: $white
      z-index: 10
      text-transform: uppercase
      font-weight: 500
      font-size: 12px
      line-height: 15px
      transition: color 0.3s
      &.active
        color: $primary
    .track
      position: absolute
      width: 50%
      top: 3px
      left: 3px
      height: $h - 6
      background: $white
      border-radius: $h/2
.theme-dark .subscription-header
  .promo:not(.active)
    color: $grey-dark
.subscription-list
  display: grid
  grid-template-columns: 2fr 2fr 3fr
  column-gap: 20px
  row-gap: 20px
  .details
    background-color: $white
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15)
    padding: 20px
    align-self: flex-start
    h6
      color: #828282
      font-weight: 800
      margin-bottom: 8px
    .standing
      border: 1px solid $green
      border-radius: 10px
      padding: 10px
      margin-bottom: 20px
      &:last-child
        margin-bottom: 0
      h6
        color: $green
        font-size: 16px
      &.error
        border-color: $red
        color: inherit
        h6
          color: $red
      ul
        padding: 0
        margin: 0
        margin-left: 15px
        font-size: 13px
        line-height: 17px
        li
          margin-bottom: 5px
    hr
      margin-bottom: 20px
      border-top: 1px solid #D5D5D5
      border-bottom: none
      width: 100%
    .detail
      color: $grey-dark
      &:not(:last-child)
        margin-bottom: 20px
      &.last-invoice, &.card-details
        > a:last-of-type
          display: block
          margin-top: 20px
      &.next-invoice
        span
          font-size: 16px
          font-weight: 800
      &.last-invoice, &.next-invoice
        .divider
          margin: 0 10px
          color: $grey-light
      &.card-details
        .brand
          text-transform: uppercase
          font-size: 12px
          margin-right: 10px
  .subscription-banner
    display: flex
    flex-direction: column
    padding: 20px
    position: relative
    .best-banner
      $size: 80px
      position: absolute
      right: 0
      top: 0
      border-left: $size solid transparent
      border-right: $size solid $blue
      border-bottom: $size solid transparent
      display: flex
      justify-content: flex-end
      span
        position: absolute
        top: 25px
        font-weight: 800
        font-size: 16px
        line-height: 0px
        right: -$size + 8px
        color: white
        transform: rotate(45deg)
    h6
      color: #828282
      margin: 0px 0
    h1
      text-align: center
      margin-top: 10px
      font-size: 1
    .fee
      text-align: center
      margin: 10px 0
    .price
      justify-content: center
      margin-top: 20px
      color: $grey-dark
      display: flex
      align-items: flex-start
      .dollar
        font-size: 50px
        line-height: 42px
    .price-period
      color: #828282
      text-align: center
      margin-top: 20px
    hr
      margin-bottom: 20px
      border-top: 1px solid #D5D5D5
      border-bottom: none
      width: 100%
    &.dimmed
      border: 1px solid $grey-light
    &:not(.dimmed)
      background-color: $white
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15)
    .feature-list
      margin-top: 0
      padding: 10px 0px
      list-style: none
      li
        font-size: 14px
        line-height: 30px
        &.disabled
          color: #D5D5D5
    .button.active
      background-color: transparent
      color: $green
      align-self: stretch
      cursor: initial
      &:hover
        color: $green
    .button.downgrade
      background-color: transparent
      color: $grey-dark
      align-self: stretch
      &:hover
        background-color: $blue
        color: $on-primary
.subscription-info
  list-style: none
  padding: 0
  margin: 0
  li
    display: flex
    flex-direction: row
    justify-content: space-between
    padding: $base-pad/2 0
    &:not(:last-child)
      border-bottom: 1px solid rgba(black, 0.2)
.theme-dark .subscription-info
  color: $text-dark
  li:not(:last-child)
    border-bottom: 1px solid rgba($white, 0.2)

.subscribe-modal
  .capitalize
    text-transform: capitalize
  p
    font-size: 14px
    margin: 0
    margin-bottom: 20px
  .first-bill
    text-align: center
    color: #828282
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 16px
    margin-top: 20px
    margin-bottom: 25px

  .modal
    min-width: 450px
    max-width: 450px

.card-form
  background: $input-bg
  padding: $base-pad/2 $base-pad/2
  border-radius: 8px
  margin-bottom: $base-pad
  > span
    padding-bottom: $base-pad/2
    +font-scale(-0.5)

.theme-dark .card-form
  background: $input-bg-dark

.subscription-success
  display: flex
  justify-content: center
  align-items: center
  font-weight: 500
  font-size: 14px
  line-height: 15px
  letter-spacing: 0.24px
  svg
    margin-right: 20px

.pricing
  h6
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 15px
    letter-spacing: 0.5px
    text-transform: uppercase
    color: #828282
  h5
    margin-bottom: 20px
    margin-top: 10px
  .line-item
    &:not(:last-of-type)
      margin-bottom: 15px
    &.total
      border-top: 1px solid #D5D5D5
      text-transform: capitalize
      padding-top: 15px
    .row
      display: flex
      justify-content: space-between
      > div:first-of-type
        color: #383838
        display: flex
        flex-direction: row
        justify-content: center
        font-size: 14px
        line-height: 16px
        strong
          margin-right: 3px
        .popover-container
          margin-left: 5px
        .popover-content
          max-width: 160px
    .price
      color: $primary
      display: flex
      flex-direction: row
      .strike
        margin-right: 10px
        text-decoration: line-through
        color: #D5D5D5
    .description
      color: #828282
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 16px

.theme-dark .pricing
  .line-item
    &.total
      border-color: $grey-dark
    .row
      > div:first-of-type
        color: $grey-light

.invoices-modal
  .modal
    min-width: 450px
    max-width: 450px
.invoice-list
  list-style: none
  padding: 0
  margin: 0
  padding-top: 5px
  li
    display: flex
    flex-direction: row
    align-items: center
    // margin: 10px 0
    font-size: 12px
    font-weight: 500
    text-transform: uppercase
    &:not(:last-of-type)
      margin-bottom: 10px
    a
      color: #383838
    .total
      margin-left: $base-pad
      margin-right: $base-pad
      text-align: center
      flex: 1
    .details
      display: flex
      flex-direction: column

.theme-dark .invoice-list a
  color: $grey-medium

.theme-dark
  .subscription-settings
    .cancel
      border-color: rgba(130, 130, 130, 0.5)
  .subscription-list
    .subscription-banner
      .price
        color: $white
      &.dimmed
        border-color: rgba(130, 130, 130, 0.5)
      &:not(.dimmed)
        background-color: #212121
    .feature-list li.disabled
      color: rgba(130, 130, 130, 0.5)
    hr
      border-color: $grey-dark
    .details
      background-color: #212121
      .detail
        color: $on-primary
