.time-entry-edit
  .page-content
    max-width: 960px
    margin: 0 auto
.transaction-cards
  .transaction-card
    .card-body
      border-top: 1px solid #ccc
      .employee
        color: lighten($on-bg, 20%)
        padding: 0 0 $base-pad/3 0
        +font-scale(2)
      .job
        color: lighten($on-bg, 20%)
      .date
        padding-bottom: $base-pad/3

.time-entry-edit
  .grid
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    column-gap: 20px
    row-gap: 20px
