.crew-edit
  .page-content
    max-width: 960px
    margin: 0 auto
.crew-cards
.crew-card
  .crew-stats
    margin-top: $base-pad/2
    display: flex
    flex-direction: row
    flex: 1
    justify-content: space-between
    align-self: stretch
    > div
      flex: 1
      display: flex
      flex-direction: column
      align-items: center
      span
        padding-top: $base-pad/4
        +font-scale(2)
  span:not(:first-child)
    padding-bottom: $base-pad/4
  .badge
  .identifier
    +font-scale(1.5)
  .name
    color: $primary
    +font-scale(2)
.dash-panel
  h2
    color: rgba($text, 0.5)
    @include font-scale(0.6)
    &
      font-weight: 200
      margin-bottom: $base-pad/2

.flex-grid
  display: flex
  .col
    margin: $base-pad/2 0
    flex: 1
    &:not(:last-child)
      margin-right: $base-pad
