.employee-cards
.employee-card
  span:not(:first-child):not(:last-child)
    padding-bottom: $base-pad/4
.name
  color: $primary
  @include font-scale(1)
.pay-rate
  @include font-scale(-0.8)
.dash-panel
  h2
    color: rgba($text, 0.5)
    font-weight: 200
    margin-bottom: $base-pad/2
    @include font-scale(0.6)

.flex-grid
  display: flex
  .col
    margin: $base-pad/2 0
    flex: 1
    &:not(:last-child)
      margin-right: $base-pad

.employee-edit
  .page-content
    max-width: 960px
    margin: 0 auto
.employee-form
  .page-content
    max-width: 960px
    margin: 0 auto
    .callout
      margin-bottom: 20px
    .row-spacer
      height: 40px
  .grid
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    column-gap: 20px
    row-gap: 20px
  .column
    display: flex
    flex-direction: column
    max-width: 260px
    flex: 1
    margin-right: 20px
    .form-input
      flex-grow: 0
      margin-bottom: 15px
      align-self: stretch
    &.note
      max-width: 540px
    &.device
      max-width: 350px
