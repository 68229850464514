// Base Colors
$white: #fff
$white-1: #FAFAFA
$grey-light: #D5D5D5
$grey-medium: #828282
$grey-dark: #383838
$grey-dark-1: #121212
$magenta: #F2647A
$dark-magenta: #D13B52
$blue: #0098FE
$light-blue: #E3E8EC
$dark-blue: #0077C7
$green: #46C94A
$dark-green: #16A01A
$red: #ED4C67
$error: $red
$legacy: #CE855A

$invalid-entry-bg: lighten(desaturate($red, 10%), 25%)
$invalid-entry-bg-dark: darken(desaturate($red, 50%), 35%)

$black: $grey-dark-1
// Primary App Colors
$bg: #fafafa
$bg-alt: #2c2f33
$content-bg: #f9fbfc

$btn-default: $green
$primary: $blue
$secondary: $green
$danger: $red

$on-bg: $black
$on-primary: $white

$clock-in-color: $green
$clock-out-color: $red
$inactive-color: #888

// Toolbar
$toolbar-bg: #fff

// Text
$text: #000
$link-color: $primary

$app-bg: $bg
$app-bg-dark: #2A2A2A
$appbar-bg: $primary

// Cards
$card-bg: #fff
$card-bg-dark: lighten($app-bg-dark, 3%)

// Layout
$base-pad: 20px

// Drawer
$drawer-bg: #fff
$drawer-bg-dark: lighten($app-bg-dark, 3%)
$drawer-text: darken($text, 20%)
$drawer-item-dark: $grey-light
$drawer-category-dark: $grey-medium
$drawer-width: 240px

// Typography
$font-scale: 0.191
$font-family: 'Lato', sans-serif
$base-font: 16px
$button-font-family: 'Lato', sans-serif

// Buttons
$button-radius: 5px
$button-label: $on-bg
$button-default-bg: $primary
$button-group-inactive-bg: lighten($primary, 20%)

// Table
$table-bg: $bg
$table-bg-dark: $drawer-bg-dark
$table-border-color: rgba($on-bg, 0.2)
$table-row-alt-bg: darken($bg, 2%)
$table-row-alt-bg-dark: darken($table-bg-dark, 2%)
$table-row-hover-bg: darken($bg, 8%)
$table-row-hover-bg-dark: darken($table-bg-dark, 6%)
$table-cell-padding: 12px 4px
$table-header-padding: $base-pad/4 4px $base-pad/1.5 4px

// Inputs
$input-bg: $light-blue
$input-bg-dark: #212121
$input-label: $grey-medium
$input-label-dark: $grey-medium
$input-unfocus-border: 2px solid rgba($on-bg, 0.2)
$input-focus-border: 2px solid $primary
$input-color: $on-bg
$input-placeholder: $grey-medium
$input-placeholder-dark: $grey-medium
$input-switch-bg: darken($bg, 15%)
$input-switch-active-bg: lighten($primary, 20%)

// Modal
$modal-bg: $white
$modal-bg-dark: #2A2A2A

// Dark Theme
$text-dark: $grey-light
