
.row
  display: flex
  flex-direction: row
  max-width: 100%
.col
  flex: 1
  display: flex
  flex-direction: column
  &:not(:last-child)
    margin-right: $base-pad

.justify-between
  justify-content: space-between

.align-center
  align-items: center
