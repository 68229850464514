.stepper
  .progress
    display: flex
    flex-direction: row
    padding-bottom: $base-pad/2
    .step-title
      flex: 1
      display: flex
      flex-direction: column
      align-items: center
      .label
        color: rgba(white, 0.5)
        &.active
          color: $primary
      .row
        padding-bottom: $base-pad /2
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        height: 20px
        .indicator
          width: 12px
          height: 12px
          border-radius: 6px
          background: transparent
          border: 2px solid rgba(white, 0.3)
          &.completed
            background-color: $primary
        .border-left, .border-right
          flex: 1
          display: block
          border: 1px solid rgba(white, 0.3)
        .active
          border-color: $primary
