
h1, .h1
  font-family: Montserrat
  font-style: normal
  font-weight: 900
  font-size: 24px
  line-height: 29px

h2, .h2
  font-family: Lato
  font-style: normal
  font-weight: 500
  font-size: 20px
  line-height: 24px
  letter-spacing: 0.8px

h3, .h3
  font-family: Lato
  font-style: normal
  font-weight: 800
  font-size: 16px
  line-height: 19px
  letter-spacing: 0.3px

h4, .h4
  font-family: Lato
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 19px
  letter-spacing: 0.3px

h5, .h5
  font-family: Lato
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 15px
  letter-spacing: 0.24px

h6, .h6
  font-family: Lato
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 15px
  letter-spacing: 0.5px
  text-transform: uppercase
  margin: 0

h7, .h7
  font-family: Lato
  font-style: normal
  font-weight: normal
  font-size: 10px
  line-height: 12px

p, .p
  font-family: Lato
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 16px

.form-label
  font-family: Lato
  font-style: normal
  font-weight: 800
  font-size: 12px
  line-height: 17px
