.page.help
  .page-content
    align-items: center
  .help-list
    flex: 1
    max-width: 1100px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: flex-start
    .column
      flex: 1
    .options
      margin-top: 20px
      > div:first-child
        margin-right: 40px
      .button
        margin-top: 20px
      p
        line-height: 20px
        font-size: 13px
      .phone
        font-weight: bold
        color: $green
      h1
        text-transform: uppercase
        font-weight: 800
        font-size: 13px
        margin-bottom: 10px

    .support-row
      margin-top: 20px
      h2
        font-weight: 500
        font-size: 19px
        line-height: 22px
    .title-row
      display: flex
      align-items: center
      flex-direction: row
    .help-item
      flex-direction: row
      margin-right: $base-pad
      margin-bottom: $base-pad
      padding: $base-pad $base-pad
      +card()
      .row-button
        display: flex
        flex: 1
        justify-content: center
      .details
        width: 100%
      p
        margin: $base-pad 0
      .icon
        margin-right: $base-pad
      &.plus
        display: flex
        background-color: $green
        color: white
        justify-content: center
        align-items: center
        h1
          text-transform: uppercase
          font-family: Lato
          font-size: 19px
          font-weight: bold
        .upgrade
          border-radius: 0
          border: 2px solid $white
          span
            font-weight: 800
        p
          text-align: center
          font-weight: 500
          font-size: 19px
          line-height: 22px
          margin: 30px 100px

.theme-dark .page.help .help-list .help-item
  background: $card-bg-dark
