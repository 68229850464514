=badge-color($c)
  background-color: $c
  border-color: $c
  color: $c
.badge
  color: $text
  background-color: $primary
  padding: $base-pad/8 $base-pad/4
  border-radius: 3px
  display: inline-flex
  align-items: center
  +font-scale(-1)
  &:not(:first-of-type)
    margin-left: $base-pad/2
  &.apple
    +badge-color($inactive-color)
  &.android
    +badge-color($green)
  &.clock-in
    +badge-color($clock-in-color)
  &.clock-out
    +badge-color($clock-out-color)
  &.inactive
    +badge-color($inactive-color)
  &.active
    +badge-color($clock-in-color)
  &:not(.outline)
    color: $text
  &.plus-feature
    margin-left: 20px
    font-weight: 800
    +badge-color(#5D5FEF)
    color: white
    svg
      color: white
      margin-right: 6px
  &.new, &.improvement, &.bug, &.web, &.mobile
    font-family: 'Lato'
    font-size: 0.6rem
    display: inline-block
    line-height: 1.5
    transform: translate(0, -2px)
    +badge-color(rgba($green, 0.5))
    color: $black
  &.improvement
    +badge-color(rgba($blue, 0.5))
    color: $black
  &.bug
    +badge-color(rgba($red, 0.5))
    color: $black
  &.mobile
    +badge-color($green)
    transform: none
    color: white
  &.web
    +badge-color($blue)
    transform: none
    color: $white
  &.outline
    border-width: 1px
    border-style: solid
    background-color: transparent
  &.beta
    display: inline-block
    text-transform: uppercase
    font-size: 0.6rem
    color: $on-primary
    margin-left: 6px

.theme-dark .badge
  &.new, &.improvement, &.bug, &.web, &.mobile
    color: $white
