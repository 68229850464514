.setting-divider
  margin: $base-pad / 2 0
  display: flex
  flex-direction: row
  align-items: center
  .line
    flex: 1
    content: ''
    height: 1px
    width: 100%
    background: rgba($on-bg, 0.3)
    &:first-child
      margin-right: 20px
    &:last-child
      margin-left: 20px
.theme-dark .setting-divider
  color: $grey-medium
  .line
    background: rgba($white, 0.1)

.page.settings
  // max-width: 960px
  // margin: 0 auto
  padding: $base-pad
  .page-content
    max-width: 960px
    margin: 0 auto
  .setting-menu
    display: flex
    flex-direction: row
    background: $white
    margin-bottom: $base-pad
    border-radius: 4px
    +elevation-2
    >div
      flex: 1
      padding: $base-pad/1.5 0
      text-align: center
      color: $on-bg
      transition: background 0.2s
      &:first-child
        border-radius: 3px 0 0 3px
      &:last-child
        border-radius: 0 3px 3px 0
      &:hover, &.active
        background: $primary
        color: $white
  .tabs
    background: $card-bg
    margin-top: 0
    +card
    .tab
      background: $card-bg
      padding-top: $base-pad/2
  .col
    padding: $base-pad/2 0
    max-width: 350px
  .setting-card
    > h1
      +font-scale(0)
  .setting
    position: relative
    & > .activity-indicator
      position: absolute
      right: $base-pad/4
      top: -$base-pad/4
      >div
        background: $primary
    .form-input
      .button
        margin-top: $base-pad/4
    .description
      margin-top: 5px
      padding: 0 5px
      color: lighten($text, 50%)
      +font-scale(-0.8)

.user-email-pending
  +font-scale(-0.8)
  .email
    padding: 0 4px
    color: darken($primary, 20%)
    font-style: italic

.card.integration
  img
    width: 100%

.setting-row
  display: flex
  flex-direction: row
  gap: 20px
  margin-bottom: 20px
.setting-col
  display: flex
  flex-direction: column
  flex: 1
  gap: 20px
.setting-group
  +card()
  overflow: visible
  border-radius: 5px
  display: flex
  padding-top: 10px
  flex-direction: column
  h6
    padding: 5px 20px
  .form-input
    padding-left: 5px
    align-self: stretch
  .form-input.calendar
    padding-left: 20px
  .setting
    align-self: stretch
    padding-bottom: 10px
    padding-left: 15px
    padding-right: 15px
    &:not(:last-child)
      margin-bottom: 5px
      border-bottom: 1px solid $grey-light
  label
    font-weight: 800
    text-transform: none
    font-size: 1rem
    color: $on-bg

.theme-dark .setting-group
  background-color: $card-bg-dark
  label
    color: $grey-medium
  .setting:not(:last-child)
    border-bottom: 1px solid rgba(0,0,0,0.25)

.period-preview
  display: flex
  flex-direction: row
  align-items: flex-start
  padding: 0 20px
  width: 100%
  position: relative

  .calendar
    display: flex
    justify-content: center
    align-items: flex-start
    transform: scale(0.75) translateX(-50%)

  .periods
    position: absolute
    right: 0
    top: 0
    display: flex
    flex: 1
    height: 100%
    padding-right: 20px
    justify-content: center
    flex-direction: column
    align-items: center
    ul
      list-style: none
      margin: 0
      padding: 0
      width: 130px
      li
        font-size: 0.8rem
        margin-bottom: 4px
        border-left: 10px solid $grey-medium
        padding-left: 5px
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        position: relative
        .icon
          position: absolute
          left: -32px
          font-size: 0.7rem
          top: 8px
        > div
          flex: 2
          display: flex
          flex-direction: column
          align-self: stretch
          .month
            font-weight: 800
            color: $grey-medium
            font-size: 0.7rem
          &.divider
            flex: 1
            display: flex
            padding: 0 4px
            font-size: 0.6rem
            justify-content: center
            align-items: center
            text-align: center
          &:last-child
            text-align: right
        &.active
          font-weight: 800
        &.p0, &.p1, &.p2, &.p4, &.p5, &.p6
          border-color: desaturate($green, 60%)
        &.p1, &.p5
          border-color: darken(desaturate($green, 60%), 7%)
        &.p0, &.p6
          border-color: darken(desaturate($green, 60%), 15%)
        &.p3
          border-color: $green
