.employees-report
  min-height: 100%
  display: flex
  flex-direction: column
  .page-content
    position: relative
    flex: 1
  .table
    padding: $base-pad/2 0 0 0
    td:first-child, th:first-child
      padding-left: $base-pad
    td:last-child, th:last-child
      text-align: right
      padding-right: $base-pad
.device-card
  &.android
    .icon
      color: $green
  .st-warn
    color: $danger

.cards
  flex: 1
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  .card
    flex: 1 1 48%
    margin-right: $base-pad
    margin-bottom: $base-pad
    &:not(.no-hover)
      cursor: pointer
    &:not(.no-hover):hover
      box-shadow: 0 0 0 2px $primary
    .name
      font-weight: 600
    .identifier
      +font-scale(0)
    @include card()
    &.invalid
      background: $invalid-entry-bg
    .row
      flex: 1
      justify-content: space-between
      align-items: center
      &:not(:last-of-type)
        margin-bottom: $base-pad/3
    .label
      font-weight: 600
      margin-right: $base-pad/4
    .card-header
      padding: $base-pad/2
      display: flex
      flex-direction: column
      width: 100%
    .card-body
      padding: $base-pad/2
      display: flex
      flex-direction: column
      font-weight: 400
      width: 100%
      .version, .sync
        small
          margin-left: $base-pad/3
      .time
        display: flex
        flex-direction: row
        align-items: center
      .badge
        margin-right: $base-pad/2
      .employee
        color: lighten($on-bg, 20%)
        padding: 0 0 $base-pad/3 0
        +font-scale(2)
      .job
        color: lighten($on-bg, 20%)
        font-weight: 600
      .date
        font-weight: 600
  .report-card
    &
      padding: 0
    img
      border-bottom: 1px solid rgba(0,0,0,0.15)
      width: 100%
      height: 150px
.theme-dark .card .card-body .employee
  color: $on-primary
@media (max-width: 800px)
  .cards .card
    flex: 1 1 99%
    margin-right: 0
@media (max-width: 1024px) and (min-width: 800px)
  .cards .card
    flex: 0 1 48%
    &:nth-child(2n+0)
      margin-right: 0
@media (max-width: 1280px) and (min-width: 1024px)
  .cards .card
    flex: 0 1 31%
    &:nth-child(3n+0)
      margin-right: 0
@media (min-width: 1280px)
  .cards .card
    flex: 0 1 23%
    &:nth-child(4n+0)
      margin-right: 0


.upgrade-banner
  display: flex
  flex-direction: column
  max-width: 50%
  padding: 15px
  border: 2px $primary solid
  border-radius: 5px
  > div
    flex: 1
  .row
    justify-content: center
