.page.messaging
  height: 100%
  display: flex
  position: relative
  flex-direction: row
  .sidebar
    width: 330px
    display: flex
    flex-direction: column
    max-height: 100vh
    .controls
      padding: 20px
      .callout
        margin-bottom: 20px
      .button
        height: 35px
      .filter-menu
        margin-left: 10px
        width: 32px
        height: 32px
        border-radius: 16px
        align-items: center
        justify-content: center
        display: flex
        &:hover
          background-color: $primary
          color: $white
      .button-row
        padding-top: 20px
      .cancel-button
        flex: 1
        margin-right: 20px
      .button.secondary
        padding: 4px
        border-radius: 4px
        &:not(:last-of-type)
          margin-right: 10px
        &.disabled
          background: none
          .label
            color: $grey-light
        .label
          color: $grey-dark
          margin: 0
        &:hover
          .label
            color: white
  .conversation-list
    flex: 1
    height: 10px
    display: flex
    flex-direction: column
    .non-ideal
      padding: 0 $base-pad
      color: $grey-medium
    .scroll-container
      flex: 1 1 auto
      overflow: auto
      min-height: 0px
    .item
      display: flex
      flex-direction: row
      align-items: center
      padding: 6px 20px
      .labels
        display: flex
        flex-direction: row
        overflow: hidden
        align-items: center
        justify-content: space-between
        .title
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        .timestamp
          font-size: 0.7rem
          color: $grey-medium
      span
        margin-left: 10px
        user-select: none
      .avatar
        font-size: 0.8rem
        width: 32px
        height: 32px
        flex: none
      .input.checkbox
        margin-right: 20px
        input:not(:checked) + .checkbox-control
          border-color: transparent
          background: $light-blue
      &:hover, &.selected
        background-color: rgba(#E3E8EC, 0.5)
  .content
    flex: 3
    background: #FFFFFF
    box-shadow: inset 3px 0px 4px rgba(150, 150, 150, 0.25)
.search-input
  position: relative
  flex: 1
  input[type="text"]
    background: $light-blue
    border: none
    border-radius: 3px
    padding: 10px 10px 10px 30px
    font-size: 0.7rem
    width: 100%
    &:focus
      outline: 1px solid $blue
    &::placeholder
      color: $grey-medium
  svg
    color: $grey-medium
    position: absolute
    top: 8px
    left: 8px
.conversation
  display: flex
  flex-direction: column
  height: 100vh
  overflow: hidden
  .chat-header
  .conversation-header
    display: flex
    flex-direction: row
    align-items: center
    min-height: 58px
    padding: 0 20px
    border-bottom: 1px solid $light-blue
    > span
      margin-right: 10px
  .contact-list
    margin-top: 10px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    .chat-contact
      display: flex
      flex-direction: row
      align-items: center
      background: rgba(227, 232, 236, 0.5)
      border-radius: 20px
      margin-right: 10px
      margin-bottom: 10px
      user-select: none
      .remove
        margin-right: 4px
        padding: 4px
        display: flex
        align-items: center
        border-radius: 15px
        &:hover
          background: $red
          color: $white
      span
        margin-left: 10px
        margin-right: 10px
      .avatar
        font-size: 0.8rem
        width: 32px
        height: 32px
      // &:hover
      //   background-color: rgba(#E3E8EC, 0.5)
  .message-container
    flex: 1
    overflow: auto
    min-height: 0
    display: flex
    flex-direction: column
    .messages
      position: relative
      flex: 1
      padding: 0 20px
      flex-direction: column
      display: flex
      justify-content: flex-end
    .info-message
      font-family: Lato
      font-style: normal
      font-weight: 500
      font-size: 20px
      line-height: 28px
      letter-spacing: 0.3px
    .employee-name
      font-weight: 800
.message-composer
  margin: 20px
  border: 1px solid $light-blue
  border-radius: 2px
  overflow: hidden
  font-family: Lato
  font-style: normal
  font-weight: normal
  font-size: 10px
  line-height: 12px
  color: rgba(130, 130, 130, 0.5)
  .controls
    border-top: 1px solid $light-blue
    display: flex
    flex-direction: row
    > div
      display: flex
      align-items: center
      padding: 0 15px
      height: 43px
      flex: 1
      .spinner
        margin-left: auto
  textarea
    border: none
    padding: 15px
    flex: 1
    width: 100%
    resize: none
    outline: none
    font-family: Lato
    font-size: 12px
    line-height: 16px
    margin: 0
    &::placeholder
      font-family: Lato
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 16px
      color: #828282
  input[type="submit"]
    background: $green
    color: $white
    border: none
    padding: 0 15px
    border-left: 1px solid $light-blue
    text-transform: uppercase
    &.disabled
      background: transparent
      color: $grey-light
.message-container .message
  padding-top: 10px
  .text
    margin: 0
    font-family: Lato
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 16px
    margin-left: 42px
    white-space: pre-wrap
    word-wrap: break-word
  .header
    display: flex
    flex-direction: row
    align-items: center
    .avatar
      font-size: 0.8rem
      width: 32px
      height: 32px
    .from
      margin: 0 10px
      font-family: Lato
      font-style: normal
      font-weight: bold
      font-size: 16px
      line-height: 19px
      letter-spacing: 0.3px
    .timestamp
      color: $grey-medium
      font-family: Lato
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 16px
    .actions
      display: flex
      flex: 1
      align-items: center
      justify-content: flex-end
      .delete-button
        margin-left: 10px
        border-radius: 50%
        width: 24px
        height: 24px
        display: flex
        align-items: center
        justify-content: center
        &:hover
          background: $red
    .status
      text-transform: capitalize
      color: $grey-medium
      font-family: Lato
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 16px
      &.seen
        color: $primary
      &.acknowledged
        color: $green

.theme-dark .page.messaging
  .sidebar
    .button.secondary
      &.disabled .label
        color: $grey-dark
      .label
        color: $grey-medium
  .dropdown-content
    background: darken($app-bg-dark, 3%)
    color: $on-primary
  .conversation-list
    .item
      &:hover, &.selected
        background-color: rgba(black, 0.3)
      .input.checkbox
        input:checked + .checkbox-control
          background: $input-bg-dark
        input:not(:checked) + .checkbox-control
          background: $input-bg-dark
  .chat-contact
    background: rgba(black, 0.4)
  .conversation-header
    border-color: rgba(black, 0.4)
  .content
    background: $drawer-bg-dark
    box-shadow: inset 3px 0px 2px rgba(black, 0.25)
  .search-input
    input[type="text"]
      background: $input-bg-dark
      color: $on-primary
  .message-composer
    border-color: rgba(black, 0.4)
    textarea
      color: $on-primary
      background: $input-bg-dark
    .controls
      color: $grey-light
      border-top: 1px solid rgba(black, 0.4)
    input[type="submit"]
      border-left: 1px solid rgba(black, 0.4)
