.input
  border-left: 3px solid $grey-medium
  padding-left: 10px
  label
    text-transform: uppercase
    font-weight: 600
    font-size: 0.8rem
    color: $on-bg
    padding-bottom: 0
    font-family: 'Montserrat'
  .form-label-row
    padding-bottom: 3px
  .badge.value
    background: transparent
    border-radius: 4px
    color: $on-bg
    border: 1px solid rgba(black, 0.3)
  .description
    font-style: italic
    margin-bottom: 10px
    color: $grey-medium

.theme-dark .input
  label
    color: $on-primary
  .badge.value
    color: white
    border: 1px solid rgba($on-primary, 0.3)

.input.range
  input
    width: 200px
  .value
    margin-left: 20px
.input.switch
  margin-bottom: 16px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  .input-row
    display: flex
    align-items: center
    > label
      padding: 0 $base-pad 0 0
  .switch-container
    position: relative
    width: 40px
    user-select: none
    label
      display: block
      overflow: hidden
      cursor: pointer
      height: 16px
      padding: 0
      line-height: 16px
      border: 0px solid #fff
      border-radius: 30px
      background: $input-switch-bg
      &:before
        content: ''
        display: block
        width: 24px
        height: 24px
        margin: -5px
        background: darken($bg, 5%)
        position: absolute
        right: 26px
        border-radius: 30px
        +shadow
    .switch-input:checked + label
      background: $input-switch-active-bg
    .switch-input:checked + label:before
      right: 0px
      background-color: $primary
      +shadow
    .switch-input
      display: none

.input.checkbox
  border-left: 0
  padding-left: 0
  .checkbox-control
    width: 24px
    height: 24px
    border-radius: 3px
    background: $white
    cursor: pointer
    border: 2px solid $grey-light
    transition: border-color 0.2s
    svg
      transition: transform 0.2s
      transform: scale(0)
      transform-origin: center
      path
        stroke: $green
  input
    display: none
    &:checked + .checkbox-control
      border-color: $green
      svg
        transform: scale(0.9)
.theme-dark .input.checkbox
  .checkbox-control
    background: $input-bg-dark
    border: 1px solid rgba(white, 0.2)
  // input::checked + .checkbox-control
  //   background: lighten($app-bg-dark, 2%)
  // input:disabled + .checkbox-control
  //   background: lighten($app-bg-dark, 2%)
  //   border: 1px solid darken($grey-medium, 20%)

.input.textarea
  border-left: 0
  padding-left: 0
  flex: 1
  display: flex
  flex-direction: column
  label
    @extend .form-label
    text-transform: none
    color: $black
  textarea
    border-radius: 5px
    border: 1px solid $grey-light
    font-family: $font-family
    font-size: 0.8rem
    resize: none
    padding: 5px
    &:focus
      border: 1px solid $primary
      box-shadow: 0
      outline: none
.theme-dark .input.textarea
  textarea
    background: $input-bg-dark
    color: $white
    border: 1px solid rgba(white, 0.2)
    &:focus
      border: 1px solid $primary

.input.radio-group
  border-left: 0
  padding-left: 0px
  align-self: stretch
  overflow: visible
  .radio-option
    display: flex
    flex-direction: row
    padding: 5px 15px
    overflow: visible
    &:hover
      cursor: pointer
      background-color: rgba(0,0,0,0.05)
    &:not(:last-child)
      border-bottom: 1px solid rgba(0,0,0,0.1)
    .description
      padding: 0
      margin: 0
      font-style: normal
      color: lighten($text, 50%)
      font-size: 14px
    .label
      font-weight: 800
    .labels
      flex: 1
  .radio-input
    margin: 10px
    border-radius: 12px
    width: 24px
    height: 24px
    padding: 2px
    border: 1px solid $grey-medium
  .radio-selected
    border-radius: 9px
    width: 18px
    height: 18px
    background: $primary

.form-input.select
  input:focus
    box-shadow: none

.form-input textarea
  font-family: $font-family

.theme-dark .input.radio-group
  .radio-option
    &:hover
      background-color: rgba(0,0,0,0.15)
  .radio-option:not(:last-child)
    border-bottom: 1px solid rgba(0,0,0,0.25)
