.dropdown-menu
  .dropdown-content
    display: flex
    flex-direction: column
    z-index: 999
    background: #EAEAEA
    font-family: Lato
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 132.3%
    padding: 5px
    color: #383838
    +elevation-3
    &[data-open^='false']
      display: none
    &[data-open^='true']
      display: flex
    &[data-popper-placement^='top'] > .arrow
      bottom: -4px
    &[data-popper-placement^='bottom'] > .arrow
      top: -4px
    &[data-popper-placement^='left'] > .arrow
      right: -4px
    &[data-popper-placement^='right'] > .arrow
      left: -4px
    .menu-item
      display: flex
      align-items: center
      padding: 8px 8px
      border-radius: 3px
      cursor: pointer
      user-select: none
      &:hover
        background: $primary
        color: $white
      svg
        margin-right: 8px
    .arrow, .arrow::before
      position: absolute
      width: 8px
      height: 8px
      background: inherit
    .arrow
      visibility: hidden
      &::before
        visibility: visible
        content: ''
        transform: rotate(45deg)
