form
  .form-col
    flex: 1
    &:not(:first-child)
      margin-right: $base-pad/2
    &:not(:first-child)
      margin-left: $base-pad/2
    &:last-child
      margin-right: 0
    .form-input:not(:first-child)
      margin-top: $base-pad
  .form-label-row
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    align-items: center
    padding-bottom: 5px
  .form-row
    display: flex
    flex: 1
    padding-bottom: 20px
    justify-content: space-between
    > .form-input:not(:last-of-type)
      margin-right: $base-pad
    &:last-of-type
      padding-bottom: 0
  .form-error
    color: $error
    font-size: 0.8rem
    margin-top: 5px
.form-label
  color: $input-label
  transition: color 0.2s, font-size 0.2s, top 0.2s
  padding-bottom: 5px
  text-transform: uppercase
  font-weight: 800
  font-size: 12px
.form-input
  flex: 1
  // align-self: flex-start
  display: flex
  flex-direction: column
  justify-content: flex-start
  min-width: 200px
  margin-right: 0
  .label-row
    align-items: center
    padding-bottom: 5px
  label
    color: $input-label
    transition: color 0.2s, font-size 0.2s, top 0.2s
    text-transform: uppercase
    font-weight: 800
    font-size: 12px
    &.error
      color: $error
  input, textarea
    background: $input-bg
    &::placeholder
      color: $input-placeholder
    &:disabled
      background: darken($input-bg, 10%)
      color: $input-placeholder
  .input-row
    display: flex
.theme-dark .form-input
  label:not(.error)
    color: $input-label-dark
  input, textarea
    color: $grey-light
    background: $input-bg-dark
    &::placeholder
      color: $input-placeholder-dark
    &:disabled
      background: darken($input-bg-dark, 10%)
      color: $input-placeholder-dark
.theme-dark .form-input.select
  .select__control
    background: $input-bg-dark
    color: $grey-light

.form-input
  &.clock-type
    label
      margin-bottom: $base-pad/2
    .button:first-of-type
    .button:last-of-type
      margin-left: $base-pad/2

  &.phone
    .PhoneInput
      background: transparent
      border: none
      color: $input-color
      border-bottom: none
      transition: border-bottom 0.3s
      backface-visibility: hidden
      position: relative
      .PhoneInputCountry
        align-items: center
        position: absolute
        top: 10px
        left: 10px
        // padding-top: 20px
        padding-left: 5px
      .PhoneInputCountrySelectArrow
        margin-left: 10px
        margin-right: 10px
        color: $grey-dark
      input
        display: flex
        border-radius: 5px
        padding-left: 70px
        flex: 1 200px

  &.textarea, &.text, &.number, &.date-picker, &.phone
    input, textarea
      width: 100%
      padding: $base-pad/2
      border-radius: 8px
      +font-scale(-0.4)
      &::placeholder
        color: $input-placeholder
    .rc-time-picker
      input
        padding: $base-pad $base-pad/2

    input:focus + label, textarea:focus + label
      top: 5px
      color: $primary

  &.textarea
    flex: 2
    textarea
      flex: 1
  &.select
    position: relative
    svg
      fill: lighten($on-bg, 40%)
    > .error .select__control:not(.select__control--is-focused)
      box-shadow: 0 0 0 2px $error
    .select__control--is-focused
      user-select: none
      border: none
      border-radius: 8
      box-shadow: 0 0 0 2px $primary
      input
        box-shadow: none
  textarea, input
    border: none
    color: $input-color
    border-radius: 3px 3px 0 0
    transition: border-bottom 0.3s
    backface-visibility: hidden
    // @include shadow-light
    &.error
      box-shadow: 0 0 0 2px $error

    &:focus
      border-radius: 8px
      box-shadow: 0 0 0 2px $primary
      // @include shadow-strong
  &.minimal
    label
      display: none
    input
      padding: $base-pad/2
      &::placeholder
        color: $input-placeholder
.form-input.switch
  span
    padding-top: 5px
    font-style: italic
    font-size: 0.8rem
  .input-row
    display: flex
    align-items: center
    justify-content: space-between
    > label
      padding: 0 $base-pad 0 0
  .switch-container
    position: relative
    width: 40px
    user-select: none
    label
      display: block
      overflow: hidden
      cursor: pointer
      height: 16px
      padding: 0
      line-height: 16px
      border: 0px solid #fff
      border-radius: 30px
      background: $input-switch-bg
      &:before
        content: ''
        display: block
        width: 24px
        height: 24px
        margin: -5px
        background: darken($bg, 5%)
        position: absolute
        right: 26px
        border-radius: 30px
        +shadow
    .switch-input:disabled + label
      opacity: 0.5
      color: red
    .switch-input:checked + label
      background: $input-switch-active-bg
    .switch-input:checked + label:before
      right: 0px
      background-color: $primary
      +shadow
    .switch-input
      display: none

.multi-select
  .react-select-container
    background: rgba(#000, 0.4)
    border-radius: 3px 3px 0 0
    border-bottom: 2px solid rgba(#fff, 0.3)
  .react-select__control

    background: transparent
    border: none
  .react-select__value-container
    padding-top: $base-pad
  .react-select__menu
    background: $card-bg

.list-picker-row
  justify-content: flex-start !important
.list-picker
  flex: 1
  &:first-child
    margin-right: 20px
  .title
    margin-bottom: $base-pad/2
    +font-scale(1)
  ul
    list-style: none
    border-bottom: $input-unfocus-border
    height: 400px
    border-radius: 3px 3px 0 0
    overflow-y: scroll
    margin: 0
    background: $input-bg
    padding: $base-pad/2 $base-pad/2 $base-pad/2 $base-pad/2
    li
      color: darken($text, 40%)
      display: flex
      align-items: center
      padding: $base-pad/3 $base-pad/2
      border-radius: 3px
      cursor: pointer
      background: $bg
      flex-direction: row
      +card
      +flex-row
      .label
        flex: 1
        display: flex
        justify-content: space-between
      .hint
        color: rgba($on-bg, 0.6)
        font-size: 12px
        text-transform: uppercase
        padding-top: 2px
      .icon
        color: rgba($on-bg, 0.2)
        margin-right: $base-pad/2
        margin-top: 2px
      &:not(:last-child)
        margin-bottom: 5px
      &.active
        background: $primary
        color: $on-primary
        .icon
          color: $on-primary
        .hint
          color: rgba($on-primary, 0.6)
      &:hover
        background: $grey-light
.theme-dark .list-picker
  ul
    background: $input-bg-dark
    li
      color: white
      background-color: lighten($input-bg-dark, 10%)
      .icon
        color: lighten($input-bg-dark, 30%)
      &.active
        background-color: $primary
        .icon
          color: white
      &:hover
        background: $grey-medium

.tile-picker
  margin-top: $base-pad/2
  max-width: 100%
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  column-gap: 20px
  row-gap: 20px
  .tile
    flex: 0 1 280px
    border: 1px dashed rgba($on-bg, 0.4)
    border-radius: 2px
    padding: $base-pad / 2
    cursor: pointer
    &.disabled
      cursor: not-allowed
    &.active
      background: $primary
      border: 1px solid $primary
      color: $on-primary
      .title
      .description
        color: darken($on-primary, 10%)
    .plus-desc
      color: $grey-medium
      padding-top: 10px
      +font-scale(-0.5)
    .description
      margin-top: $base-pad/3
      color: darken($text, 40%)
      +font-scale(-0.5)
    .title
      text-transform: uppercase
      font-weight: bold
      display: flex
      flex-direction: row
      justify-content: space-between
      +font-scale(-0.5)
.theme-dark .tile-picker
  .tile
    background: $drawer-bg-dark
    box-shadow: 0px 4px 8px #212121
    // border: 1px solid $app-
    .description
      color: $grey-medium
    &.active
      background: $primary
      .description
        color: $white
.DayPicker-wrapper
  margin-bottom: 0
.calendar
  &.selected-red
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
      background: darken($red, 10%)
.DayPicker-Day
  flex: 1
  &--period
    background: $green
    border-radius: 0
    color: $on-primary
    &:hover
      color: $on-bg
  &.p0, &.p1, &.p2, &.p4, &.p5, &.p6
    background: desaturate($green, 60%)
  &.p1, &.p5
    background: darken(desaturate($green, 60%), 7%)
  &.p0, &.p6
    background: darken(desaturate($green, 60%), 15%)
  &--disabled
    pointer-events: none
    color: lighten($on-bg, 70%)
  &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
    background: darken($green, 10%)
  &--outside
    border-bottom: none
    background: transparent
  &--start, &--start1
    border-top-left-radius: 20px
    border-bottom-left-radius: 20px
  &--end, &--end1
    border-top-right-radius: 20px
    border-bottom-right-radius: 20px
  &.p3
    background: $green
    color: $on-primary
  &--today
    color: $on-primary
    font-size: 1.1rem
    text-decoration: underline
    &:not(.DayPicker-Day--selected):not(.DayPicker-Day--period)
      color: $green

.help-text
  max-width: 650px
  display: flex
  flex-direction: row

.select-group-label
  display: flex
  justify-content: space-between
  align-items: center
  padding-bottom: $base-pad/3
  margin: 0
  border-bottom: 1px solid rgba(0,0,0,0.1)
  span:last-child
    background: rgba(0,0,0,0.2)
    color: $on-bg
    border-radius: 8px
    padding: 4px $base-pad/2
.theme-dark .select-group-label
  span:last-child
    color: $on-primary

.theme-dark .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover
  background-color: darken($grey-medium, 10%)
.theme-dark .DayPicker
  .DayPicker-Caption
    color: $grey-light
  .DayPicker-Day
    color: $grey-light
    &--today
      color: $on-primary
    &--disabled
      color: darken($grey-medium, 20%)
    &--today
      color: $on-primary
    &--outside
      color: darken($grey-medium, 20%)

.theme-dark .form-input.phone
  .PhoneInput
    .PhoneInputCountrySelectArrow
      color: white

.device-setting-row
  display: flex
  flex-direction: row
  gap: 20px
  margin-bottom: 20px
.device-setting-col
  display: flex
  flex-direction: column
  flex: 1
  gap: 20px
.device-setting-group
  +card()
  overflow: visible
  border-radius: 5px
  padding: 0px
  padding-top: 10px
  display: flex
  flex-direction: column
  h6
    padding: 5px 15px
  .form-input
    padding-left: 15px
    padding-right: 15px
    align-self: stretch
  label
    font-weight: 800
    text-transform: none
    font-size: 1rem
    color: $on-bg
  hr
    width: 100%
    border: none
    padding: 0
    margin: 0
    border-bottom: 1px solid rgba(0,0,0,0.1)

.device-setting
  display: flex
  flex-direction: row
  align-self: stretch
  padding: 6px 15px
  &.clickable
    cursor: pointer
    &:hover
      background-color: rgba(0,0,0,0.05)
  &:not(:last-of-type)
    border-bottom: 1px solid rgba(0,0,0,0.1)
  &.inset
    padding-left: 30px
  &.disabled
    pointer-events: none
    .label
      color: $grey-medium
    .description
      color: $grey-medium
  > div
    flex: 1
  .label
    font-weight: 800
  .description
    font-size: 14px
    color: lighten($text, 50%)
  .input-column
    flex: 0
    padding: 10px

.theme-dark .device-setting-group
  background-color: $card-bg-dark
  label
    color: $grey-medium
  hr
    border-bottom: 1px solid rgba(0,0,0,0.25)
  .device-setting
    &.clickable
      &:hover
        background-color: rgba(0,0,0,0.15)
  .device-setting:not(:last-of-type)
    border-bottom: 1px solid rgba(0,0,0,0.25)
