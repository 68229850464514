.table-scroll-wrapper
.table
  background: $table-bg
  scroll-padding: 0
  +card()
  &
    overflow: auto
    overflow: overlay
    padding: $base-pad/2 0 0 0
  &:not(.no-hover)
    table tbody tr
      &:hover
        cursor: pointer
  table
    flex: 1
    width: 100%
    border-collapse: collapse
    td:first-child, th:first-child
      padding-left: $base-pad
    td:last-child, th:last-child
      text-align: right
      padding-right: $base-pad
      .badge
        float: right
    tbody
      tr
        &:not(:last-child)
          border-bottom: 1px solid $table-border-color
        &:nth-child(even)
          background: $table-row-alt-bg
        &:hover
          background: $table-row-hover-bg
        &.invalid
          background: $invalid-entry-bg 
        td
          padding: $table-cell-padding
          +font-scale(-0.5)
    th
      text-align: left
      padding: $table-header-padding
      border-bottom: 2px solid $table-border-color
      +font-scale(-1)
      > span
        cursor: pointer
        display: inline-flex
        align-items: center
      .sort-icons
        display: inline-flex
        flex-direction: column
        align-items: center
        padding: 0 10px
        .desc
          width: 0
          height: 0
          border-left: 5px solid transparent
          border-right: 5px solid transparent
          border-top: 5px solid rgba($on-bg, 0.2)
        .asc
          width: 0
          height: 0
          border-left: 5px solid transparent
          border-right: 5px solid transparent
          border-bottom: 5px solid rgba($on-bg, 0.2)
          margin-bottom: 3px

        &.desc .desc
            border-top-color: $on-bg
        &.asc .asc
            border-bottom-color: $on-bg
      .sort
        margin-left: $base-pad/4
        &.desc
          transform-origin: 8px 8px
          transform: scale(1, -1)
.theme-dark .table
  color: $grey-light
  background: $table-bg-dark
  table th .sort-icons
    .desc
      border-top-color: rgba($text-dark, 0.2)
    .asc
      border-bottom-color: rgba($text-dark, 0.2)
    &.desc .desc
        border-top-color: $text-dark
    &.asc .asc
        border-bottom-color: $text-dark
  tbody tr
    &.invalid
      background: $invalid-entry-bg-dark 
  tbody tr:nth-child(even)
    background: $table-row-alt-bg-dark
  tbody tr:hover
    background: $table-row-hover-bg-dark
