$progress-bar-height: 3px
.tv-mode
  position: absolute
  top: 0
  left: 0
  min-height: 100%
  width: 100%
  background: darken($app-bg, 8%)
  z-index: 9999
  .modal-container.advanced-feature
    position: fixed
    .modal
      position: fixed
  .modal
    min-width: 700px
    h2
      font-family: 'Montserrat'
      text-transform: uppercase
      font-size: 1rem
      margin-bottom: 5px
      color: lighten($on-bg, 30%)
    .modal-body .form-row > .form-col:first-child
      min-width: 400px
  .empty-state
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin-top: 80px
  .progress-bar
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: $progress-bar-height
    background: darken($primary, 20%)
    z-index: 300
    .progress
      width: 0px
      height: $progress-bar-height
      background: $primary
      transform: translate3d()
      &.enabled
        width: 100%

  .toolbars .toolbar
    .back-button
      margin-right: 20px
    .form-input
      min-width: 60px
      align-self: center
      margin-right: 20px
  &.page .page-content
    padding: 0

.theme-dark .tv-mode .modal h2
  color: darken($grey-light, 20%)

.tv-mode-button
  margin-left: 20px

.tv-cards
  flex: 1
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  padding: 5px
  background: darken($app-bg, 8%)
  > div
    width: (100% / 6)
    display: flex
    padding: 5px
  .tv-card
    width: 100%
    display: flex
    border-radius: 5px
    overflow: hidden
    flex-direction: column
    background-color: white
    +elevation-3
    .card-date
      padding: 0 $base-pad/2 0 $base-pad/2
      width: 100%
      font-size: 0.9rem
      font-weight: 200
      font-style: italic
    .card-header
      padding: $base-pad / 2
      padding-bottom: 0
      display: flex
      width: 100%
      flex-direction: row
      align-items: center
      justify-content: space-between
    .card-body
      padding: $base-pad / 2
    h1
      font-size: 1.1rem
      color: $primary
      text-overflow: ellipsis
      overflow: hidden
      padding-right: 5px
    // &:not(.no-hover)
    //   cursor: pointer
    // &:not(.no-hover):hover
    //   box-shadow: inset 0px 0px 0px 2px $primary
    .employee-status
      font-style: italic
      padding: 4px 10px
    .name
      font-weight: 600
    .identifier
      +font-scale(0)
    &.invalid
      background: $invalid-entry-bg
    .row
      flex: 1
      justify-content: space-between
      align-items: center
      &:not(:last-of-type)
        margin-bottom: $base-pad/3
    .label
      font-weight: 600
      margin-right: $base-pad/4

.theme-dark
  .tv-mode, .tv-cards
    background-color: darken($app-bg-dark, 4%)
  .tv-card
    background-color: $card-bg-dark
    // background-color: transparent
// @media (max-width: 800px)
//   .tv-cards .tv-card
//     flex: 1 1 99%
//     margin-right: 0
// @media (max-width: 1024px) and (min-width: 800px)
//   .tv-cards .tv-card
//     flex: 0 1 48%
//     &:nth-child(2n+0)
//       margin-right: 0
// @media (max-width: 1280px) and (min-width: 1024px)
//   .tv-cards .tv-card
//     flex: 0 1 31%
//     &:nth-child(3n+0)
//       margin-right: 0
// @media (min-width: 1280px)
//   .tv-cards .tv-card
//     flex: 0 1 23%
//     &:nth-child(4n+0)
//       margin-right: 0
