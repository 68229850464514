a.button
  text-decoration: none
  display: block
.button
  font-family: $button-font-family
  display: flex
  align-items: center
  justify-content: center
  color: $button-label
  line-height: normal
  -webkit-appearance: none
  font: inherit
  margin: 0
  border: 2px solid
  border-color: transparent
  background: $primary
  padding: $base-pad/2 $base-pad
  text-align: center
  border-radius: 30px
  cursor: pointer
  transition: background 0.3s, color 0.3s
  user-select: none
  font-weight: 800
  +button-color($green, $dark-green)
  +font-scale(-1.1)
  .label
    margin: 0 $base-pad/2
  /* &.secondary
  /*   background: transparent
  /*   &:not(:hover) .activity-indicator > div
  /*     background: $on-bg
  &:focus
    outline: 0
  &.icon-button
    width: 42px
    height: 42px
    border-radius: 16px
  &.minimal
    background: transparent
    border: none
    box-shadow: none
    text-shadow: none
    color: $on-bg
    .icon
      margin-right: 0
    &:hover
      color: $on-primary
  .label
    text-transform: uppercase
  &.full-width
    flex: 1
  &.loading
    &.secondary
      .activity-indicator > div
        background: $on-bg
      &:hover
        .activity-indicator > div
          background: $on-primary
    .label, .icon
      opacity: 0
    .activity-indicator
      position: absolute
  &.small
    padding: 4px 8px
  &.green
    +button-color($green, $dark-green)
  &.blue
    +button-color($blue, $dark-blue)
  &.grey
    +button-color($grey-medium, $grey-dark)
  &.red
    +button-color($magenta, $dark-magenta)
    &.secondary
      color: $magenta
  &.danger
    +button-color($magenta, $dark-magenta)
  &.clock-in
    +button-color($green, $dark-green)
  &.clock-out
    +button-color($magenta, $dark-magenta)
  &.outline
    background: rgba(1, 1, 1, 0.0)
    border-width: 2px
    border-style: solid
  &.transparent
    background: transparent
    color: $on-bg
    text-shadow: none
    border: none
    &:hover
      color: $white
      .activity-indicator > div
        background: $on-primary
    .activity-indicator
      > div
        background: $on-bg
  &.disabled
    background: $grey-medium
    cursor: not-allowed
    &:hover
      background: $grey-medium
      color: inherit

.theme-dark .button
  color: $white
  &.secondary
    .activity-indicator > div
      background: $on-primary
  &.secondary:not(.red)
    color: $white

.button-group
  display: flex
  flex-direction: row
  .button
    border-radius: 0
    box-shadow: initial
    &.secondary
      background: #EDEDED
    &.active
      +button-color($blue, $dark-blue)
    &:first-child
      border-radius: $button-radius 0 0 $button-radius
    &:last-child
      border-radius: 0 $button-radius $button-radius 0

.theme-dark .button-group
  .button.secondary:not(.active)
    background: $grey-medium

.button-list
  .button:not(first-child)
    margin-top: $base-pad/2

.material-icon-button
  border-radius: 50%
  width: 30px
  height: 30px
  display: flex
  justify-content: center
  align-items: center
  transition: background 0.3s, color 0.3s
  color: $on-bg
  cursor: pointer
  &:hover
    color: $on-primary
    background: $blue

.theme-dark
  .material-icon-button
    color: $white
