@keyframes bg-fade
  0%
    background: transparent

  100%
    background: rgba(0,0,0,0.4)

.modal-container
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  z-index: 9999
  &::before
    content: ''
    z-index: 200
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    animation-duration: 0.3s
    animation-name: bg-fade
    animation-fill-mode: forwards
  &.reset-password
    .modal
      max-width: 400px
      h5
        margin-top: 0
        margin-bottom: 20px

  .modal
    z-index: 300
    background: $bg
    border: none
    border-radius: 5px
    position: absolute
    min-width: 400px
    max-width: 800px
    @include shadow-strong

    .modal-header
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      padding-bottom: 15px
      margin: $base-pad $base-pad $base-pad $base-pad
      border-bottom: 1px solid $grey-light
      h1
        margin: 0
        padding: 0

    .modal-title
      padding: 20px

    .modal-footer
      display: flex
      justify-content: center
      padding-top: $base-pad
      border-top: 1px solid $grey-light
      margin: 25px $base-pad $base-pad $base-pad
      .group
        flex: 1
        margin-right: auto
      & > .button:not(:first-child)
        margin-left: 10px

    .modal-body
      padding: 0 $base-pad
      min-width: 200px
  &.user-email
    .modal
      max-width: 500px
  &.side-modal
    position: absolute
    align-items: flex-start
    justify-content: flex-end
    padding: $base-pad 0
    .modal
      flex: 1
      width: 50%
      min-width: 400px
      border-radius: 3px 0 0 3px
    &::before
      position: absolute

.modal-container.advanced-feature
  $p: 40px
  position: absolute
  background: rgba(black, 0.4)
  &::before
    position: absolute
    backdrop-filter: blur(4px)
  .modal
    width: 420px
    max-width: 420px
    min-width: 420px
    background: linear-gradient(146.96deg, #245BEA 10.72%, #01AAFF 80.3%)
  .modal-body
    padding: 0 $p 0 $p
  .modal-header
    justify-content: flex-end
    border-bottom: none
    margin-top: 0
    padding: 10px ($p - 10) ($p / 2) $p
    margin: 0
  h1
    color: white
    font-size: 34px
    line-height: 40px
    margin-bottom: 20px
  p
    color: white
  .modal-footer
    border-top: none
    padding: $p $p $p $p
    margin: 0
    justify-content: space-between
  .material-icon-button
    color: white
    &:hover
      background: $red
  .button
    width: 150px
    &:not(.outline)
      background: white
      color: $blue
      &:hover
        background: $green
        color: white
    &.outline
      border-color: white
      color: white
      &:hover
        background: white
        color: $on-bg

.device-upgrade-modal .modal
  width: 500px

.theme-dark .modal-container
  .modal
    background: $modal-bg-dark
    color: $text-dark
    .modal-header
      border-bottom-color: $grey-dark
    .modal-footer
      border-top-color: $grey-dark
