$height: 34px
.pay-period
  display: flex
  height: $height
  align-items: center
  .pay-period-popover
    background: $card-bg
    padding: $base-pad
    border-radius: 4px
    z-index: 999
    +elevation-5
  .input-bar
    display: flex
    flex-direction: row
    .chevron-button
      display: flex
      align-items: center
      cursor: pointer
      border-radius: 20px
      width: 34px
      height: 34px
      justify-content: center
      &:hover
        background: darken($bg, 10%)
    >span
      height: $height
      line-height: $height
      text-align: center
      font-weight: 800
      cursor: pointer
      padding: 0 10px
      min-width: 240px
      border-radius: 20px
      &:hover
        background: darken($bg, 10%)
.theme-dark .pay-period
  .chevron-button, .input-bar >span
    color: $grey-light
    &:hover
      background: rgba($black, 0.4)
